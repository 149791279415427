*{
  box-sizing: border-box;
}
li{
  list-style: none;
}
.headerWrapper-Wrapper a{
  text-decoration:none;
   font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   color:white;
   font-size: 14px;
   text-transform: uppercase;
}
h1,h2,h3,h4,h5,h6{
  margin:0;
  padding:0;
}

div.exitButton{
  font-weight: 900;
  color: white;
  z-index: 20;
  position: fixed;
  top: 5px;
  pointer-events: all;
  cursor: pointer;
  right: 10px;
  border: white solid 1px;
  padding: 5px 15px;
  font-size: 2rem;
  line-height: 2rem;
}

footer, footer p{
  margin:0;
  padding:0;
}
footer{
  padding: 10px;
  margin-top: -8px;
}
.cardsWrapper h2 {
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: 700;
  font-style: italic;
  color: #1b6354
}
.bottomWrapper{
  /* width:100vw; */
}
.bottomWrapper img{
  max-width: 100%;
}
.cardsWrapper p {
  font-family: 'Roboto', sans-serif;
}

.cardsWrapper a, footer a {
  text-decoration: none;
  text-decoration-color: #006455;
  color: rgb(0, 0, 238);
}
.cardsWrapper a:visited {
  color: rgb(0, 0, 238);
}
a:hover{
  color:#006455;
}

.fontAndColor{
  color: white;
  font-size: 1.8rem;
  text-shadow: 1px 0px 10px #1b6354;
  font-family: 'Kaushan Script', 'Helvetica Neue', Helvetica, Arial, cursive;
}
input.input{
  font-size:20px;
}
.ourStory{
  padding:10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  width: 60%;
  margin: auto;
  text-align: justify;
}
.storyWrapper{
  padding:10px;
  padding-top: 20px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  justify-content: space-evenly;
}
.howWeMet h5, .proposal h5{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

h4{
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.headerWrapper{
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  padding: 0 20px;
}

.transportation li, .accomodation li{
  list-style: unset;
}

.headerWrapper-Wrapper{
  position: relative;
  top: 0;
  margin-bottom: -80px;
  width: 100vw;
  overflow: hidden;
  height: 80px;
  transition: height .25s ease;
  z-index: 2;
}
.navbar-nav{
  display: grid;
  grid-auto-flow: column;
}

.navbar-nav-parent{
  align-self: center;
}

.heroWrapper>img{
  max-width: 100%;
}

.heroWrapper{
  position: relative;
  z-index: 1;
}
.weddingCard{
  text-align: center;
  font-size: 2em
}
.titleWrapper{
  position: absolute;
    color: white;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    z-index: 3;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.title{
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 100;
  padding-bottom:10px;
  margin:0;
  font-size: 2em;
}
.titleDate{
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin:0;
  padding:0;
  font-size: 4em;
}
.hamburgerMenu{
  width: 14px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  border-radius: 5px;
}
.hamburgerWrapper{
  padding: 4px;
}

.menuWrapper{
  text-align: center;
  display: none;
  cursor: pointer;
  justify-content: center;
  background: #1b6354;
  align-content: center;
  padding: 5px 0px;
  width: 100px;
  border-radius: 5px;
  grid-auto-flow: column;
  grid-gap: 5px;
  grid-template-rows: 25px;
  grid-template-columns: 40% 20%;
  justify-content: center;
  justify-self: right;
}
.card{
  padding:20px 30px;
}
.light{
  background:#f8f6f6;
}

.dark{
  background:#f2f2f2;
}

.cardsWrapper{
  text-align: center;
  line-height: 1.25;
}
.whenAndWhere{
  grid-template-columns: 48% 1% 48%;
  justify-items: center;
  display: grid;
  justify-content: space-around;
}

.buttonModal{
  margin: 10px;
    padding: 10px 15px;
    outline: none;
    border: none;
    background: #1b6354;
    color: white;
    font-weight: 100;
    font-size: 1em;
    box-shadow: rgba(0, 0, 0, 0.3) -3px 3px 10px 0px;
    cursor: pointer;
    transition: all 0.4s;
}
.buttonModal:hover{
  background: white;
  color: #1b6354;
}
.whenAndWhereLine{
  background: black;
  width: 1px;
  height: 50%;
  justify-self: center;
  justify-items: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gettingThere .whenAndWhereLine{
  background: black;
  height: 1px;
  width: 25%;
  display: inline-block;
  justify-self: center;
  justify-items: center;
  align-self: center;
}
.gettingThere, .giftProviders {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(min-content, 350px);
  justify-content: center;
}
.transportation>ul, .accomodation>ul {
  display: inline-block;
  text-align:left;
}

.gettingThere p, .giftProviders p {
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
}

.gettingThereLine{
  background: black;
  width: 1px;
  height: 50%;
  justify-self: center;
  justify-items: center;
  align-self: center;
}

.bottomWrapper>img{
  width: 100%;
  height: 100%;
}

.bottomWrapper{
  position: relative;
  z-index: 1;
}

.footer {
  background-color: #f8f6f6;
  text-align: center;
  font-family: 'Sorts Mill Goudy', serif;
  font-size: 16px;
  font-weight: 300;
}
.menu{
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.giftProviders ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.giftProviders li {
  display: inline;
  padding:10px;
}

/* modal section */
.modal {
  position: fixed; /* Stay in place */
  z-index: -1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
.modal.open{
  z-index: 10; /* Sit on top */
}

.blur {
  filter: blur(5px);
  transition: ease;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


@media only screen and (max-width: 768px){
  .navbar-nav-parent{
    display:none;
  }
  .ourStory{
    width: auto;
  }
  .menuWrapper{
    display: grid;
    align-self: center;
  }
  .headerWrapper-Wrapper{
    background: #212529;
    position: static;
    margin: 0;
  }
  
  .fontAndColor{
    font-size: 1.4rem;
  }
  .titleWrapper{
    font-size: 12px;
  }
  .whenAndWhereLine{
    background: black;
    height: 1px;
    width: 25%;
  }
  .whenAndWhere{
    grid-template-rows: 48% 1% 48%;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

}
@media only screen and (max-width: 350px){
  .whenAndWhereLine{
    width: 50%;
  }
}

.mobile-nav-links{
  display: grid;
  grid-gap: 10px;
  padding-left: 20px;
}

.open-nav{
  height:230px;
}

.formGrid{
  display: grid;
}


.giftProviders>ul{
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 50px;
  justify-items: center;
}