
/* cyrillic-ext */


body{
  margin:0;
  padding:0;
  
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gTD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3g3D_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gbD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gfD_vx3rCubqg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/montserrat/v12/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/kaushanscript/v6/vm8vdRfvXFLG3OLnsO15WYS5DG72wNJVMJ8br5Y.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kaushan Script';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/kaushanscript/v6/vm8vdRfvXFLG3OLnsO15WYS5DG74wNJVMJ8b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/droidserif/v8/tDbK2oqRg1oM3QBjjcaDkOr4nAfcHi6FRUI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Droid Serif';
  font-style: italic;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/droidserif/v8/tDbX2oqRg1oM3QBjjcaDkOr4lLz5CwOnSGjW7BA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 400;
  src:   url(https://fonts.gstatic.com/s/droidserif/v8/tDbI2oqRg1oM3QBjjcaDkOr9rAXWGQyH.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 700;
  src:   url(https://fonts.gstatic.com/s/droidserif/v8/tDbV2oqRg1oM3QBjjcaDkOJGiRD7OwGtT0rU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qqh5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qOh5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1quh5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qSh5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qih5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qmh5DKAc_jQRQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 100;
  src:   url(https://fonts.gstatic.com/s/robotoslab/v7/BngOUXZYTXPIvIBgJJSb6u-u1qeh5DKAc_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCLwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCCwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCKwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCFwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCJwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCIwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u9mxLCGwR2oefDo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufA5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufJ5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufB5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufO5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufC5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufD5qWr4xCCQ_k.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngMUXZYTXPIvIBgJJSb6ufN5qWr4xCC.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CLwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CCwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CKwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CFwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CJwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CIwR2oefDofMY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/robotoslab/v7/BngRUXZYTXPIvIBgJJSb6u92w7CGwR2oefDo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.grecaptcha-badge{
  opacity: 0;
}

.verifyInput{
  display: inline-block;
}

.phoneAuthWrapper {
  
}
.phoneAuthWrapper form{
  display: grid;
  -webkit-justify-content: center;
          justify-content: center;
  /* grid-template-rows: 1fr 1fr 1fr; */
  margin-top: 10px;
  min-height: 100px;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 50px;
}

.phoneAuthWrapper form > * {
  margin: 0px 10px 10px 0px;
}

.phoneAuthWrapper label {
  line-height: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

.phoneAuthWrapper input, .phoneAuthWrapper select{
  margin-bottom: 20px;
  height: 40px;
  padding: 10px;
}

.phoneAuthWrapper form>div {
  grid-row: none;
}
/* * {
    margin: 0;
    padding: 0;
    color: inherit;
    box-sizing: inherit;
  }
  *:focus {
    outline: none;
  }
  
  html {
    box-sizing: border-box;
  }
  
  body {
    background-color: #ecf0f1;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
  }
  
  h1, h2, h3, h4, h5 {
    font-weight: normal;
  } */
  
  form {
    display: block;
  }
  
  label,
  input {
    display: block;
  }
  
  .animate, .btn, .btn span, .overlay .overlay-content, .overlay .overlay-content .overlay-close, .overlay .overlay-content .signup-form input {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  
  main {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  .main-heading {
    line-height: 100%;
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .btn {
    display: block;
    height: 50px;
    line-height: 50px;
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
  }
  .btn span {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }
  .btn span .fa {
    margin-left: 8px;
    font-size: 18px;
    vertical-align: middle;
  }
  .btn:not(.btn-outline) {
    background-color: #fff;
  }
  .btn:not(.btn-outline) span {
    color: #666;
  }
  .btn:not(.btn-outline):hover {
    background-color: #f7f7f7;
  }
  .btn.btn-outline {
    background-color: transparent;
    border: 1px solid #fff;
  }
  .btn.btn-outline span {
    color: #fff;
  }
  .btn.btn-outline:hover {
    background-color: #fff;
  }
  .btn.btn-outline:hover span {
    color: #666;
  }
  
  .popup-trigger {
    width: 175px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
  }
  
  .overlay {
    width: 100%;
    height: 100%;
    overflow: auto;
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: opacity 0.5s, z-index 0s 0.5s, -webkit-transform 0.5s;
    -webkit-transition: opacity 0.5s, z-index 0s 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s 0.5s, -webkit-transform 0.5s;
  }
  .overlay .overlay-background {
    background-color: rgba(60, 60, 60, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .overlay .overlay-content {
    width: 100%;
    max-width: 530px;
    margin: 100px auto 0;
    padding: 0 15px;
    color: #fff;
    position: relative;
    z-index: 10;
  }
  .overlay .overlay-content .overlay-close {
    display: block;
    font-size: 35px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
  }
  .overlay .overlay-content .overlay-close:hover {
    opacity: .8;
  }
  .overlay .overlay-content .blurb {
    margin-top: 35px;
    line-height: 100%;
    font-weight: 600;
    font-size: 18px;
  }
  .overlay .overlay-content .blurb-tagline {
    display: block;
    margin-top: 10px;
    line-height: 100%;
    font-size: 14px;
  }
  .overlay .overlay-content .signup-form {
    margin-top: 35px;
  }
  .overlay .overlay-content .signup-form label {
    line-height: 100%;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .overlay .overlay-content .signup-form input {
    background-color: transparent;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    padding: 0 8px;
    border: 1px solid rgba(254, 254, 254, 0.3);
    border-radius: 5px;
  }
 .formGrid input:focus, .verifyRequest input:focus, .verifyCode input:focus, .verifyInput input:focus, .verifyInput select:focus {
    outline: 3px solid #1b6354 !important;
  }
  .overlay .overlay-content .signup-form input:hover, .overlay .overlay-content .signup-form input:focus {
    border-color: #fff;
  }
  .overlay .overlay-content .signup-form .submit-btn {
    width: 150px;
  }
  .overlay.open {
    z-index: 15;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: opacity 0.5s, z-index 0s, -webkit-transform 0.5s;
    -webkit-transition: opacity 0.5s, z-index 0s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s, -webkit-transform 0.5s;
  }
  .overlay.short .overlay-content {
    margin: 30px auto;
  }
  
  div#parentmodal > div.overlay > div.formSubmitMessage {
    text-align: center;
    margin: 100px auto 100px auto;
  }

  .formSubmitMessage a {
    text-decoration: underline;
    -webkit-text-emphasis-color: white;
            text-emphasis-color: white;
    -webkit-text-decoration-color: white;
            text-decoration-color: white;
    color:  white;
  }

  .formSubmitMessage h4 {
    text-align: justify;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 900;
  }

  @media (max-width: 600px) {
    .overlay .overlay-content {
      margin: 25px auto;
    }
  }

*{
  box-sizing: border-box;
}
li{
  list-style: none;
}
.headerWrapper-Wrapper a{
  text-decoration:none;
   font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
   color:white;
   font-size: 14px;
   text-transform: uppercase;
}
h1,h2,h3,h4,h5,h6{
  margin:0;
  padding:0;
}

div.exitButton{
  font-weight: 900;
  color: white;
  z-index: 20;
  position: fixed;
  top: 5px;
  pointer-events: all;
  cursor: pointer;
  right: 10px;
  border: white solid 1px;
  padding: 5px 15px;
  font-size: 2rem;
  line-height: 2rem;
}

footer, footer p{
  margin:0;
  padding:0;
}
footer{
  padding: 10px;
  margin-top: -8px;
}
.cardsWrapper h2 {
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: 700;
  font-style: italic;
  color: #1b6354
}
.bottomWrapper{
  /* width:100vw; */
}
.bottomWrapper img{
  max-width: 100%;
}
.cardsWrapper p {
  font-family: 'Roboto', sans-serif;
}

.cardsWrapper a, footer a {
  text-decoration: none;
  -webkit-text-decoration-color: #006455;
          text-decoration-color: #006455;
  color: rgb(0, 0, 238);
}
.cardsWrapper a:visited {
  color: rgb(0, 0, 238);
}
a:hover{
  color:#006455;
}

.fontAndColor{
  color: white;
  font-size: 1.8rem;
  text-shadow: 1px 0px 10px #1b6354;
  font-family: 'Kaushan Script', 'Helvetica Neue', Helvetica, Arial, cursive;
}
input.input{
  font-size:20px;
}
.ourStory{
  padding:10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  width: 60%;
  margin: auto;
  text-align: justify;
}
.storyWrapper{
  padding:10px;
  padding-top: 20px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 10px;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}
.howWeMet h5, .proposal h5{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: italic;
}

h4{
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.headerWrapper{
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  padding: 0 20px;
}

.transportation li, .accomodation li{
  list-style: unset;
}

.headerWrapper-Wrapper{
  position: relative;
  top: 0;
  margin-bottom: -80px;
  width: 100vw;
  overflow: hidden;
  height: 80px;
  -webkit-transition: height .25s ease;
  transition: height .25s ease;
  z-index: 2;
}
.navbar-nav{
  display: grid;
  grid-auto-flow: column;
}

.navbar-nav-parent{
  -webkit-align-self: center;
          align-self: center;
}

.heroWrapper>img{
  max-width: 100%;
}

.heroWrapper{
  position: relative;
  z-index: 1;
}
.weddingCard{
  text-align: center;
  font-size: 2em
}
.titleWrapper{
  position: absolute;
    color: white;
    top: 0;
    bottom: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    left: 0;
    z-index: 3;
    text-align: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.title{
  font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 100;
  padding-bottom:10px;
  margin:0;
  font-size: 2em;
}
.titleDate{
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
  margin:0;
  padding:0;
  font-size: 4em;
}
.hamburgerMenu{
  width: 14px;
  height: 2px;
  background-color: white;
  margin: 2px 0;
  border-radius: 5px;
}
.hamburgerWrapper{
  padding: 4px;
}

.menuWrapper{
  text-align: center;
  display: none;
  cursor: pointer;
  -webkit-justify-content: center;
          justify-content: center;
  background: #1b6354;
  -webkit-align-content: center;
          align-content: center;
  padding: 5px 0px;
  width: 100px;
  border-radius: 5px;
  grid-auto-flow: column;
  grid-gap: 5px;
  grid-template-rows: 25px;
  grid-template-columns: 40% 20%;
  justify-content: center;
  justify-self: right;
}
.card{
  padding:20px 30px;
}
.light{
  background:#f8f6f6;
}

.dark{
  background:#f2f2f2;
}

.cardsWrapper{
  text-align: center;
  line-height: 1.25;
}
.whenAndWhere{
  grid-template-columns: 48% 1% 48%;
  justify-items: center;
  display: grid;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.buttonModal{
  margin: 10px;
    padding: 10px 15px;
    outline: none;
    border: none;
    background: #1b6354;
    color: white;
    font-weight: 100;
    font-size: 1em;
    box-shadow: rgba(0, 0, 0, 0.3) -3px 3px 10px 0px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}
.buttonModal:hover{
  background: white;
  color: #1b6354;
}
.whenAndWhereLine{
  background: black;
  width: 1px;
  height: 50%;
  justify-self: center;
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gettingThere .whenAndWhereLine{
  background: black;
  height: 1px;
  width: 25%;
  display: inline-block;
  justify-self: center;
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
}
.gettingThere, .giftProviders {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(-webkit-min-content, 350px);
  grid-template-columns: minmax(min-content, 350px);
  -webkit-justify-content: center;
          justify-content: center;
}
.transportation>ul, .accomodation>ul {
  display: inline-block;
  text-align:left;
}

.gettingThere p, .giftProviders p {
  text-align: justify;
  margin-bottom: 0px;
  font-size: 14px;
}

.gettingThereLine{
  background: black;
  width: 1px;
  height: 50%;
  justify-self: center;
  justify-items: center;
  -webkit-align-self: center;
          align-self: center;
}

.bottomWrapper>img{
  width: 100%;
  height: 100%;
}

.bottomWrapper{
  position: relative;
  z-index: 1;
}

.footer {
  background-color: #f8f6f6;
  text-align: center;
  font-family: 'Sorts Mill Goudy', serif;
  font-size: 16px;
  font-weight: 300;
}
.menu{
  color: white;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-transform: uppercase;
}

.giftProviders ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.giftProviders li {
  display: inline;
  padding:10px;
}

/* modal section */
.modal {
  position: fixed; /* Stay in place */
  z-index: -1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
.modal.open{
  z-index: 10; /* Sit on top */
}

.blur {
  -webkit-filter: blur(5px);
          filter: blur(5px);
  -webkit-transition: ease;
  transition: ease;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}


@media only screen and (max-width: 768px){
  .navbar-nav-parent{
    display:none;
  }
  .ourStory{
    width: auto;
  }
  .menuWrapper{
    display: grid;
    align-self: center;
  }
  .headerWrapper-Wrapper{
    background: #212529;
    position: static;
    margin: 0;
  }
  
  .fontAndColor{
    font-size: 1.4rem;
  }
  .titleWrapper{
    font-size: 12px;
  }
  .whenAndWhereLine{
    background: black;
    height: 1px;
    width: 25%;
  }
  .whenAndWhere{
    grid-template-rows: 48% 1% 48%;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

}
@media only screen and (max-width: 350px){
  .whenAndWhereLine{
    width: 50%;
  }
}

.mobile-nav-links{
  display: grid;
  grid-gap: 10px;
  padding-left: 20px;
}

.open-nav{
  height:230px;
}

.formGrid{
  display: grid;
}


.giftProviders>ul{
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  height: 50px;
  justify-items: center;
}
