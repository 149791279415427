/* * {
    margin: 0;
    padding: 0;
    color: inherit;
    box-sizing: inherit;
  }
  *:focus {
    outline: none;
  }
  
  html {
    box-sizing: border-box;
  }
  
  body {
    background-color: #ecf0f1;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;
  }
  
  h1, h2, h3, h4, h5 {
    font-weight: normal;
  } */
  
  form {
    display: block;
  }
  
  label,
  input {
    display: block;
  }
  
  .animate, .btn, .btn span, .overlay .overlay-content, .overlay .overlay-content .overlay-close, .overlay .overlay-content .signup-form input {
    transition: all 0.3s;
  }
  
  main {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
  
  .main-heading {
    line-height: 100%;
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .btn {
    display: block;
    height: 50px;
    line-height: 50px;
    border: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
  }
  .btn span {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }
  .btn span .fa {
    margin-left: 8px;
    font-size: 18px;
    vertical-align: middle;
  }
  .btn:not(.btn-outline) {
    background-color: #fff;
  }
  .btn:not(.btn-outline) span {
    color: #666;
  }
  .btn:not(.btn-outline):hover {
    background-color: #f7f7f7;
  }
  .btn.btn-outline {
    background-color: transparent;
    border: 1px solid #fff;
  }
  .btn.btn-outline span {
    color: #fff;
  }
  .btn.btn-outline:hover {
    background-color: #fff;
  }
  .btn.btn-outline:hover span {
    color: #666;
  }
  
  .popup-trigger {
    width: 175px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
  }
  
  .overlay {
    width: 100%;
    height: 100%;
    overflow: auto;
    float: left;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    opacity: 0;
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: opacity 0.5s, z-index 0s 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s 0.5s, -webkit-transform 0.5s;
  }
  .overlay .overlay-background {
    background-color: rgba(60, 60, 60, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .overlay .overlay-content {
    width: 100%;
    max-width: 530px;
    margin: 100px auto 0;
    padding: 0 15px;
    color: #fff;
    position: relative;
    z-index: 10;
  }
  .overlay .overlay-content .overlay-close {
    display: block;
    font-size: 35px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 15px;
  }
  .overlay .overlay-content .overlay-close:hover {
    opacity: .8;
  }
  .overlay .overlay-content .blurb {
    margin-top: 35px;
    line-height: 100%;
    font-weight: 600;
    font-size: 18px;
  }
  .overlay .overlay-content .blurb-tagline {
    display: block;
    margin-top: 10px;
    line-height: 100%;
    font-size: 14px;
  }
  .overlay .overlay-content .signup-form {
    margin-top: 35px;
  }
  .overlay .overlay-content .signup-form label {
    line-height: 100%;
    margin-bottom: 5px;
    font-size: 14px;
  }
  .overlay .overlay-content .signup-form input {
    background-color: transparent;
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    padding: 0 8px;
    border: 1px solid rgba(254, 254, 254, 0.3);
    border-radius: 5px;
  }
 .formGrid input:focus, .verifyRequest input:focus, .verifyCode input:focus, .verifyInput input:focus, .verifyInput select:focus {
    outline: 3px solid #1b6354 !important;
  }
  .overlay .overlay-content .signup-form input:hover, .overlay .overlay-content .signup-form input:focus {
    border-color: #fff;
  }
  .overlay .overlay-content .signup-form .submit-btn {
    width: 150px;
  }
  .overlay.open {
    z-index: 15;
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: opacity 0.5s, z-index 0s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s;
    transition: opacity 0.5s, transform 0.5s, z-index 0s, -webkit-transform 0.5s;
  }
  .overlay.short .overlay-content {
    margin: 30px auto;
  }
  
  div#parentmodal > div.overlay > div.formSubmitMessage {
    text-align: center;
    margin: 100px auto 100px auto;
  }

  .formSubmitMessage a {
    text-decoration: underline;
    text-emphasis-color: white;
    text-decoration-color: white;
    color:  white;
  }

  .formSubmitMessage h4 {
    text-align: justify;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 900;
  }

  @media (max-width: 600px) {
    .overlay .overlay-content {
      margin: 25px auto;
    }
  }
